import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "react-intl"

import Link from "~/components/Link/Link"
import { LocalizedIngredient } from "~/models/IngredientFacet"
import { Ingredient } from "~/models/Recipe"

const SEPARATOR = "||"

export default function useIngredients() {
  const intl = useIntl()

  const data: LocalizedIngredient[] = useStaticQuery(graphql`
    query IngredientsQuery {
      allIngredients {
        nodes {
          slug
          name
          language
        }
      }
    }
  `).allIngredients.nodes

  const sortBySlug = (a: LocalizedIngredient, b: LocalizedIngredient) => {
    if (a.slug < b.slug) {
      return -1
    } else if (a.slug > b.slug) {
      return 1
    }
    return 0
  }

  const [ingredients] = useState<LocalizedIngredient[]>(
    data
      .filter(ingredient => ingredient.language === intl.locale)
      .sort(sortBySlug)
  )

  const getIngredientByName = (name: string) => {
    const filteredIngredients = ingredients.filter(
      ingredient => ingredient.name === name
    )
    if (filteredIngredients.length) {
      return filteredIngredients[0]
    }
  }

  const getIngredientBySlug = (slug: string) => {
    const filteredIngredients = ingredients.filter(
      ingredient => ingredient.slug === slug
    )
    if (filteredIngredients.length) {
      return filteredIngredients[0]
    }
  }

  const formatIngredient = (ingredient: Ingredient) => {
    const machingIngredient = getIngredientBySlug(ingredient.ingredient.slug)

    if (!machingIngredient) {
      return ingredient.result
    }

    //From "1 oz of sugar" to ["1 oz of ", "sugar", ""]
    const ingredientTextParts = ingredient.result
      .replace(
        ingredient.ingredient.name,
        `${SEPARATOR}${ingredient.ingredient.name}${SEPARATOR}`
      )
      .split(SEPARATOR)

    return ingredientTextParts.map((part, index) => (
      <React.Fragment key={index}>
        {index === 1 ? ( //As we split the string by adding "SEPARATOR" before and after ingredient name, the ingredient name will always be at the index 1
          <Link
            to="all-ingredients-sitemap/ingredient-sitemap"
            params={{
              ingredient: machingIngredient.slug,
            }}
            style={{
              textDecoration: "none",

              borderBottom: "1px dashed lightgray",
            }}
          >
            {part}
          </Link>
        ) : (
          part
        )}
      </React.Fragment>
    ))
  }

  return {
    ingredients,
    getIngredientByName,
    getIngredientBySlug,
    formatIngredient,
  }
}
