import React from "react"

import { useIntl } from "react-intl"

import withTemplate from "../withTemplate"
import Link from "~/components/Link/Link"
import SEO from "~/components/Seo/Seo"
import { Body, Container, Heading } from "~/components/ui"
import useIngredients from "~/hooks/useIngredients"
import { capitalizeFirstLetter } from "~/utils/string"

function IngredientPage() {
  const intl = useIntl()
  const { ingredients } = useIngredients()

  const title = intl.formatMessage({
    id: "ingredients-list-sitemap/text:title",
  })
  const description = intl.formatMessage({
    id: "ingredients-list-sitemap/text:description",
  })

  return (
    <>
      <SEO title={title} description={description} />
      <Container>
        <Heading variant="extraLarge" Tag={"h1"}>
          {title}
        </Heading>
        <ul>
          {ingredients.map(ingredient => (
            <li key={ingredient.slug}>
              <Link
                to="all-ingredients-sitemap/ingredient-sitemap"
                params={{
                  ingredient: ingredient.slug,
                }}
              >
                <Body>{capitalizeFirstLetter(ingredient.name)}</Body>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </>
  )
}
export default withTemplate(IngredientPage)
